/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

body {
  color-scheme: only light;
  background: #fff;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-light, #cccccc);
  //--swiper-pagination-color: var(--ion-color-dark, #3880ff);
  --swiper-pagination-color: radial-gradient(
    circle,
    var(--ion-color-light, #cccccc) 0%,
    var(--ion-color-light, #cccccc) 40%,
    var(--ion-color-dark, #3880ff) 60%,
    var(--ion-color-dark, #3880ff) 100%
  );
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.ion-color-rcred {
  --ion-color-base: var(--ion-color-rcred);
  --ion-color-base-rgb: var(--ion-color-rcred-rgb);
  --ion-color-contrast: var(--ion-color-rcred-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rcred-contrast-rgb);
  --ion-color-shade: var(--ion-color-rcred-shade);
  --ion-color-tint: var(--ion-color-rcred-tint);
}

.ion-color-rcgreen {
  --ion-color-base: var(--ion-color-rcgreen);
  --ion-color-base-rgb: var(--ion-color-rcgreen-rgb);
  --ion-color-contrast: var(--ion-color-rcgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rcgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-rcgreen-shade);
  --ion-color-tint: var(--ion-color-rcgreen-tint);
}

.ion-color-rcyellow {
  --ion-color-base: var(--ion-color-rcyellow);
  --ion-color-base-rgb: var(--ion-color-rcyellow-rgb);
  --ion-color-contrast: var(--ion-color-rcyellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rcyellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-rcyellow-shade);
  --ion-color-tint: var(--ion-color-rcyellow-tint);
}

ion-button.ion-color-rcyellow[disabled] {
  --ion-color-base: #cfd8dc;
}

.alert-message {
  &-error {
    margin: 2px 0;
    color: var(--ion-color-rcred);
  }
}

.alert-wrapper {
  .alert-button {
    color: var(--ion-color-dark);
  }

  .alert-button.alert-button-role-destructive {
    color: var(--ion-color-rcred);
  }

  .alert-radio-button {
    --ion-color-primary: var(--ion-color-dark) !important;
  }
}

.action-sheet-wrapper {
  .action-sheet-button {
    color: var(--ion-color-dark);
  }

  .action-sheet-button.action-sheet-destructive {
    color: var(--ion-color-rcred);
  }
}

.map-container-inner {
  height: 100%;
}

.address input.searchbar-input {
  font-size: 18px;
}

ion-item.rcitem {
  &:focus-within {
    --border-color: var(--ion-color-rcyellow);

    ion-input {
      --highlight-color-focused: var(--ion-text-color);
      --highlight-color-valid: var(--ion-text-color);
    }
    ion-textarea {
      --highlight-color-focused: var(--ion-text-color);
      --highlight-color-valid: var(--ion-text-color);
    }
  }
  ion-select {
    --highlight-color-focused: var(--ion-text-color);
    --highlight-color-valid: var(--ion-text-color);
  }

  ion-input.input-label-placement-fixed.rcinput-fixed-wide {
    .label-text-wrapper {
      max-width: 70%;
      flex-shrink: 0;
    }
  }
}

#support-tab-chat-container {
  height: 100%;
  overflow: hidden;

  // chaport creates iframe
  iframe.chaport-inner-iframe {
    height: calc(100% + 32px);
  }
}
