// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-color-rcyellow: #ffe642;
  --ion-color-rcyellow-rgb: 255, 230, 66;
  --ion-color-rcyellow-contrast: #000000;
  --ion-color-rcyellow-contrast-rgb: 0, 0, 0;
  --ion-color-rcyellow-shade: #e0ca3a;
  --ion-color-rcyellow-tint: #ffe955;

  --ion-color-rcgreen: #19842d;
  --ion-color-rcgreen-rgb: 25, 132, 45;
  --ion-color-rcgreen-contrast: #ffffff;
  --ion-color-rcgreen-contrast-rgb: 255, 255, 255;
  --ion-color-rcgreen-shade: #167428;
  --ion-color-rcgreen-tint: #309042;

  --ion-color-rcred: #cc434d;
  --ion-color-rcred-rgb: 204, 67, 77;
  --ion-color-rcred-contrast: #ffffff;
  --ion-color-rcred-contrast-rgb: 255, 255, 255;
  --ion-color-rcred-shade: #b43b44;
  --ion-color-rcred-tint: #d1565f;

  --ion-color-primary: #224477;
  --ion-color-primary-rgb: 34, 68, 119;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1e3c69;
  --ion-color-primary-tint: #385785;
}
